import styles from './styles.module.css'



type Props = {
    title: string,
    onPress?: () => void;
foto?:string
}

export const Button = ({ title, onPress,foto }: Props) => {
    return (
        <header>
            <div className={styles.wrapper}  >
                <button onClick={onPress} className={styles.button}>{title}
                <img src={foto} alt="" />
                </button>
            </div>
        </header>
    );

}