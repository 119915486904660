import { api } from "../Api/axios"

const baseURL = "/formulario"


export const UserCadastroService = async (dados: Object,) => {
  const resposta = await api.post(baseURL+`/cadastro${dados}`)
  console.log(resposta)
  return resposta;
}

export const buscarPorId = async (id: string | undefined) => {
  const resposta = await api.get(`${baseURL}/${id}`);
  return resposta.data

}

export const getByEmail = async (email: string) => {
  const data = await api.get(`${baseURL}/${email}`);
  return data
}

