
import { Routes, Route, } from 'react-router-dom';
import { Home } from '../pages/home';

export function Router() {
    return (

        <Routes >           
            <Route path="/Home/:id" element={<Home />} />           
        </Routes>
    )

}
