import { Button } from "../../components/button";
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { buscarPorId } from "../../service/user-service";
import { generateVcard } from "../../service/vcard-service";

import styles from "./styles.module.css";
import User from "../../model/user";

function Home() {
  const { id } = useParams<{ id: string | undefined }>();
  const [user, setUser] = useState<User>();
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useParams<{ token: string | undefined }>();

  async function handleSync() {
    setIsLoading(true);
    try {
      const userExists = await buscarPorId(id);
      setUser(userExists);
    } catch (error) {
      throw error;
    }
    setIsLoading(false);
  }

  useEffect(() => {
    handleSync();
  }, [id]);


  const copyChavePix = async () => {
    if (user?.chavePix) {
      await navigator.clipboard
        .writeText(user.chavePix)
        .then(() => {
          alert("Chave Pix copiada para a área de transferência!");
        })
        .catch((err) => {
          console.error("Erro ao copiar chave Pix: ", err);
        });
    }
  };

  const Vcard = async () => {
    try {
      if (!user || !user.id) {
        throw new Error("Usuário inválido ou sem ID.");
      }
      const response = await generateVcard(user?.id);
      const blob = new Blob([response], { type: "text/vcard" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "contact.vcf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Erro ao gerar vCard:", error);
    }
  };

  return (
    <body style={{ backgroundColor: user?.cor }}>
      <div
        style={{ backgroundColor: user?.cor }}
        className={styles.containerBody}
      >
        <div className={styles.container}>
          <div className={styles.containerInfo}>
            <div className={styles.imgContainer}>
              {user?.foto && (
                <img
                  src={user?.foto}
                  style={{ borderRadius: 100, width: 150, height: 150 }}
                  alt="Imagem do perfil"
                />
              )}
            </div>
          </div>
          <div className={styles.textWrapper}>
            {user?.nomeExibicao && (
              <b style={{ fontSize: 28 }} className={styles.inputTextNameFirst}>
                {user?.nomeExibicao}
                <br />
              </b>
            )}
            {user?.cargoDeExibicao && (
              <i
                style={{ fontSize: 18 }}
                className={styles.inputTextNameSegund}
              >
                {user?.cargoDeExibicao}
              </i>
            )}
          </div>
        </div>

        <div className={styles.containerButtons}>
          <Button onPress={Vcard} title="Salvar na agenda" />

          {user?.linkInstagram && (
            <a
              className={styles.links}
              href={user?.linkInstagram}
              target="_blank"
              rel="noreferrer"
            >
              <Button title="Instagram" />
            </a>
          )}

          {user?.linkWhattsApp && (
            <a
              className={styles.links}
              href={`https://api.whatsapp.com/send?phone=${user?.linkWhattsApp}`}
              target="_blank"
              rel="noreferrer"
            >
              <Button title="WhatsApp" foto="whatsapp.svg" />
            </a>
          )}

          {user?.linkLinkedin && (
            <a
              className={styles.links}
              href={user?.linkLinkedin}
              target="_blank"
              rel="noreferrer"
            >
              <Button title="LinkedIn" />
            </a>
          )}

          {user?.github && (
            <a
              className={styles.links}
              href={user?.github}
              target="_blank"
              rel="noreferrer"
            >
              <Button title="GitHub" />
            </a>
          )}

          {user?.linkSite && (
            <a
              className={styles.links}
              href={user?.linkSite}
              target="_blank"
              rel="noreferrer"
            >
              <Button title="Site" />
            </a>
          )}

          {user?.chavePix && (
            <a className={styles.links}>
              <Button title="Chave Pix" onPress={copyChavePix} />
            </a>
          )}

          {user?.apresentacao && (
            <a
              className={styles.links}
              href={user?.apresentacao}
              target="_blank"
              rel="noreferrer"
            >
              <Button title="Sobre a empresa" />
            </a>
          )}

          <div className={styles.footer}>
            <a
              className={styles.links}
              href="https://rellou.club/"
              target="_blank"
              rel="noreferrer"
            >
              Storaze Company
            </a>
          </div>
        </div>
      </div>
    </body>
  );
}

export { Home };
